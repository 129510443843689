@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");

/* common css */

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
li {
  list-style-type: none;
}
a:hover {
  text-decoration: none;
}
button {
  cursor: pointer;
}
input:focus {
  outline: none;
}
textarea:focus {
  outline: none;
}
select:focus {
  outline: none;
}

.container_fluid {
  width: 100%;
  float: left;
  min-height: 100vh;
  background: #f4faff
}

.container {
  max-width: 1280px;
  clear: both;
  padding: 10px;
  margin: 0 auto;
}
.common_container{
  background: #ffffff;
  padding: 20px;
}
img {
  max-width: 100%;
  display: block;
}

input,
textarea,
select,
button {
  font-family: "Montserrat", sans-serif;
}
/* a{color: #1897f2;} */

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #000000;
  opacity: 1;
}
input::-moz-placeholder,
textarea::-webkit-input-placeholder {
  color: #000000;
  opacity: 1;
}
input:-ms-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #000000;
  opacity: 1;
}
input:-moz-placeholder,
textarea::-webkit-input-placeholder {
  color: #000000;
  opacity: 1;
}

/* header css */
.header_container_fluid {
  background-color: #901941;
  height: 100px;
  border-radius: 0px 0px 50px 50px;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
}
.header_content {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.header_content h1 {
  font-size: 40px;
  font-family: "Quicksand", sans-serif;
  color: #ffffff;
}


/* create case admin css */

.content_img_case{

width: 100%;


}
/* .cover_image_case{
  border: 1px solid #000000;
  padding: 10px 20px;
} */
/* media css */

/* 
@media (max-width: 500px) {


} */
@media (min-width: 501px) and (max-width: 575px) {
  .modal_user {
    width: 100%;
    max-width: 400px !important;
    padding: 20px;
  }
  .header_content h1 {
    font-size: 20px;
  }
}
@media (max-width: 500px) {
  /* .header_images{
    display: none;
  } */
  .header_content h1 {
    font-size: 20px;
  }
  .modal_user {
    width: 100%;
    max-width: 350px !important;
    padding: 20px;
  }
}
@media (max-width: 500px) {
  .header_content h1 {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .Home_content {
    flex-direction: column;
    padding: 0px 20px;
  }

  /* .visit_container{
  display: flex;
  flex-direction: column;
  align-items: center;
} */
  .visit_container_sub {
    width: 100%;
  }
}

@media (min-width: 768.1px) and (max-width: 1120px) {
  .cover_conatiner {
    width: 80% !important;
  }
}
@media (min-width: 375px) and (max-width: 575px) {
  .cover_conatiner {
    width: 80% !important;
  }
}
@media (min-width: 768.1px) and (max-width: 1120px) {
  .Home_content {
    flex-direction: column;
    padding: 0px 20px;
  }

  /* .visit_container{
    display: flex;
    flex-direction: column;
    align-items: center;
  } */
  .visit_container_sub {
    width: 100%;
  }
}

/* sidebar csssss */
.sidebar_container {
  /* display: flex; */
  margin: 0px auto;
  background: #ffffff;
  /*   width: 1350px; */
}

.sidebar_contant {
  /* transition: all 0.2s; */
  background: #ffffff;
}

.cover_conatiner {
  width: 100%;
  margin-left: 97px;
}
.top_section {
  display: flex;
  align-items: center;
  padding: 30px 0px;
  justify-content: center;
}
.input_text_error_span {
  display: none;
}
.icon{
  display: flex;
  align-items: center;
  /* justify-content: center; */
}



/* sidebar csssss */
.sidebar_container {
  display: flex;
  margin: 0px auto;
  background: #f2f8fd;
}

.sidebar_contant {
  /* transition: all 0.2s; */
  background: #ffffff;
  border-radius: 5px;
}

main {
  width: 100%;
}
.top_section {
  display: flex;
  align-items: center;
  padding: 48px 33px 13px 23px;
  gap: 48px;
}
.input_text_error_span{
  display: none;
}
.link {
  display: flex;
  color: #6e7191;
  padding: 10px 15px;
  text-decoration: none;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  gap: 25px;
  margin-bottom: 14px;
  font-family: "Outfit", sans-serif;
}


.link.active {
  position: relative;
}


.link.active .link_text::after {
  /* content: url("../src/assets/images/navigater.svg"); */
  position: absolute;
  top: 49%;
  right: 17px;
  transform: translateY(-50%);
  width: 8px;
  height: 31px;
}

.link:hover {
  color: #163e6e;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}


.icon-wrapper {
  width: 45px;
}
.link_text {
  align-self: center;
}

.link:last-child {
  margin-top: 10px;
}

.signup_container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.signup_content h1 {
  color: #163e6e;
  /* color: #901941; */
  text-align: center;
  font-family: "Outfit", sans-serif;
  font-size: 40px;
  font-weight: 600;
  margin: 20px 0px;
}
.signup_container_left_typo {
  color: #000000;
  /* color: #901941; */
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 600;
}
.input_label {
  color: #163e6e;
  /* color: #901941; */
  text-align: center;
  font-family: "Outfit", sans-serif;
  font-size: 20px;
  font-weight: 600;
}
.input_text_error {
  color: #ff0000;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-weight: 600;
}
.user-details-strong {
  color: #901941;
  font-family: "Quicksand", sans-serif;
  font-size: 18px;
  font-weight: 500;
}

.login_password {
  margin: 15px 0px;
}
.error {
  color: red;
  padding-left: 5px;
  font-family: "Montserrat", sans-serif;
}
.inputStyle {
  padding: 15px 10px;
  font-weight: 500;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  border: none;
  border: 2px solid #163e6e;
  background: none;
  border-radius: 20px;
}

.inputError {
  padding: 15px 10px;
  font-weight: 500;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  border-radius: 20px;
  border: none;
  border: 1px solid #ff0000;
  background: none;
  /* margin-bottom: 20px; */
}
.inputStyleToggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid #163e6e;
  box-sizing: border-box;
  margin-bottom: 10px;
  color: #979797;
  padding: 15px 10px;
  margin-bottom: 20px;
  border-radius: 20px;
}
.inputErrorToggle {
  display: flex;

  justify-content: space-between;
  align-items: center;
   border: 1px solid #ff0000;
  box-sizing: border-box;
  /* margin-bottom: 10px; */
  color: #979797;
  padding: 15px 10px;
  /* margin-bottom: 20px; */
  border-radius: 20px;
}

.signin_button {
  padding: 15px;
  font-weight: 600;
  font-size: 18px;
  font-family: "Outfit", sans-serif;
  background: #163e6e;
  color: #ffffff;
  border: 2px solid #163e6e;
  border-radius: 20px;
}

.signin_button:hover {
  color: #163e6e;
  background: #ffffff;
}
.login_gap {
  display: flex;
  flex-direction: column;
}
.inputpass {
  border: none;
  outline: none;
  background: none;
  flex: 1;
  font-weight: 500;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
}
.forgot_password {
  display: flex;
  justify-content: end;
  margin-bottom: 20px;
  cursor: pointer;
}
.forgot_password a {
  text-decoration: none;
  color: #000000;
  font-weight: 500;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
}
.logo_login {
  display: flex;
  justify-content: center;
  margin: 10px 0px;
}
.logo_login img {
  width: 177px;
  height: 65px;
}
.edit_heading {
  font-weight: 600;
  font-size: 25px;
  font-family: "Quicksand", sans-serif;
  color: #901941;
  margin-bottom: 20px;
  text-align: center;
}

.edit_gap {
  margin-top: 20px;
}
.modal_user {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 800px;
}

/* test */
.case_inner_container {
  display: flex;
  justify-content: center;
  align-items: center;

}
.caseInner_head {
  max-width: 800px;
}
.have_solution_heading {
  font-size: 30px;
  font-weight: 600;
  color: #163e6e;
  text-align: center;
  margin-bottom: 20px;
  font-family: "Outfit", sans-serif;
}
.case_studies_inner_container {
  max-width: 900px;
}
.case_inner_image {
  width: 100%;
  margin-bottom: 10px;
}
.case_inner_text h1,
.case_studies_inner_container h1 {
  color: #163e6e;
  font-size: 30px;
  font-weight: 600;
  line-height: 25px;
  font-family: "Outfit", sans-serif;
  margin-bottom: 20px;
}
.case_inner_text p,
.case_studies_inner_container > p {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 25px;
}
.case_inner_text p{
  text-align: justify;
}

.iphone-frame {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}
.cover_images {
  width: 100%;
  height: auto;
  border-radius: 20px;
  border: 4px solid #000000;
}
.iphone_mid_content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.iphone_mid_content img {
  width: 100px;
  height: 100px;
}
.iphone_mid_content a {
  font-size: 18px;
  text-decoration: none;
  color: #ffffff;
  font-family: "Outfit", sans-serif;
  font-weight: 600;
}